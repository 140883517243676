<script setup lang="ts">
import WindowDialogMounter from './components/Provider/WindowDialogMounter.vue'
import WindowMessageMounter from './components/Provider/WindowMessageMounter.vue'

const themeOverrides = {
  common: {
    primaryColor: '#323377',
    primaryColorHover: '#323377',
    primaryColorPressed: '#323377',
  },
}
</script>

<template>
  <NConfigProvider :themeOverrides="themeOverrides">
    <NMessageProvider>
      <NDialogProvider>
        <WindowDialogMounter>
          <WindowMessageMounter>
            <RouterView />
          </WindowMessageMounter>
        </WindowDialogMounter>
      </NDialogProvider>
    </NMessageProvider>
  </NConfigProvider>
</template>

<style scoped></style>
