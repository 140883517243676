// 路由守卫
import { tokenHandler } from '../apis/tokenHandler'
import router from '../router'

// 设置无需鉴权的路径
const allowEnterPath: string[] = [
  '/login',
  '/404',
  '/middleware/facebook',
  '/middleware/google',
  '/privacy',
  '/issue',
  '/home',
]
router.beforeEach(async (to, from, next) => {
  const token = tokenHandler.get()
  if (!token) {
    const allowRoute: boolean = allowEnterPath.some((path) => to.path === path)
    if (!allowRoute) {
      next({ name: 'Login' })
    } else {
      next()
    }
  } else {
    next()
  }
})
