import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Panel',
      component: () => import('@/views/Panel/Panel.vue'),
    },
    {
      path: '/middleware/:type',
      name: 'Middleware',
      component: () => import('@/views/Middleware/Middleware.vue'),
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Login/Login.vue'),
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: () => import('@/views/Privacy/Privacy.vue'),
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: () => import('@/views/Privacy/Privacy.vue'),
    },
    {
      path: '/issue',
      name: 'Issue',
      component: () => import('@/views/Issue/Issue.vue'),
    },
  ],
})

export default router
