<script lang="ts" setup>
import type { DialogApi } from 'naive-ui'

window.$dialog = useDialog()
declare global {
  interface Window {
    $dialog: DialogApi
  }
}
</script>

<template>
  <slot></slot>
</template>
