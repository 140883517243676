<script lang="ts" setup>
import type { MessageApi } from 'naive-ui'

window.$message = useMessage()
declare global {
  interface Window {
    $message: MessageApi
  }
}
</script>

<template>
  <slot></slot>
</template>
